<template>
  <div id="CategoryDetailPage">
    <div v-if="categoryDetailInfo" class="section">
      <div class="container">
        <div class="columns is-multiline is-centered">
          <!-- Category Story -->
          <div class="column is-12">
            <p class="title">
              {{ categoryDetailInfo.title }}
            </p>
          </div>
          <!-- Main Image -->
          <div class="column is-7">
            <figure class="image is-3by2">
              <img
                class="img-cover"
                :src="categoryDetailInfo.main_image"
                alt="Placeholder image"
              />
            </figure>
          </div>
          <!-- Category Story -->
          <div class="column is-5" style="text-align: left">
            <p class="pre-formatted" style="text-indent: -5px;">
              {{ categoryDetailInfo.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <section class="hero is-large">
        <div class="hero-body">
          <div class="container">
            <h2 class="title">
              {{ $t("label.loading") }}
            </h2>
          </div>
        </div>
      </section>
    </div>

    <hr />
    <!-- PRPDUCT VARIANTS -->
    <div class="section">
      <div class="container">
        <h1 class="title">
          {{ $t("category_detail.product_variants") }}
        </h1>
        <h2 class="subtitle">
          {{ $t("category_detail.product_variants_subtitle") }}
        </h2>
        <div v-if="categoryProducts">
          <div
            v-if="categoryProducts.length > 0"
            class="columns is-multiline is-variable is-6"
          >
            <div
              v-for="(product, index) in categoryProducts"
              :key="index"
              class="column is-3"
            >
              <product-card :productItem="product" />
            </div>
          </div>
          <div v-else>
            <section class="hero">
              <div class="hero-body">
                <div class="container">
                  <h2 class="title">
                    <b-icon
                      icon="emoticon-sad-outline"
                      size="is-large"
                      type="is-primary"
                    />
                  </h2>
                  <h2 class="subtitle">
                    {{ $t("category_detail.no_product_variants") }}
                  </h2>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div v-else>
          <section class="hero is-large">
            <div class="hero-body">
              <div class="container">
                <h2 class="title">
                  {{ $t("label.loading") }}
                </h2>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import Helper from "@/utils/helper";
import ProductCard from "@/components/cards/ProductCard.vue";
import ProductApiManager from "@/api/ProductApiManager";

export default {
  name: "CategoryDetailPage",
  components: {
    ProductCard
  },
  filters: {
    replaceEnterKeyWithTag: function(value) {
      return Helper.toHtmlNewLineTag(value);
    }
  },
  watch: {
    current_lang: function(val) {
      if (val) {
        this.categoryProducts = null;

        const category_id = this.$route.params.category;
        this.getCategoryDetail(category_id);
        this.getCategoryProducts(category_id);
      }
    }
  },
  computed: mapState(["current_lang"]),
  data() {
    return {
      categoryDetailInfo: null,
      categoryProducts: null
    };
  },
  methods: {
    getCategoryDetail: async function(category_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await ProductApiManager.requestCategoryDetail(
        category_id
      );
      switch (response.code) {
        case "0":
          this.categoryDetailInfo = response.data;
          break;

        default:
          break;
      }
      loadingComponent.close();
    },
    getCategoryProducts: async function(category_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await ProductApiManager.requestCategoryProducts(
        category_id
      );
      switch (response.code) {
        case "0":
          this.categoryProducts = response.data;
          break;

        default:
          break;
      }
      loadingComponent.close();
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    const category_id = this.$route.params.category;
    this.getCategoryDetail(category_id);
    this.getCategoryProducts(category_id);
  }
};
</script>

<style scoped>
.img-cover {
  object-position: center;
  object-fit: cover;
}
</style>
